<template lang='pug'>
include ../pug/index
</template>

<script>
export default {
  name: "Index",
  // props: {
  //   msg: String
  // }
  data() {
    return {
      logo: require("./assets/img/logo_tekinventive/logo_tekinventive.png"),
      eferu_photo: require("./assets/img/team/eferu_photo.jpg"),
      cferu_photo: require("./assets/img/team/cferu_photo.jpg"),
      dpatrascu_photo: require("./assets/img/team/dpatrascu_photo.jpg"),
      ddediu_photo: require("./assets/img/team/ddediu_photo.jpg"),

      // logo_tridec: require('./assets/img/clients/logo_tridec.png'),
      logo_pianoled: require("./assets/img/clients/logo_pianoled.png"),

      services_1: require("./assets/img/services/1.jpg"),
      services_2: require("./assets/img/services/2.jpg"),
      services_3: require("./assets/img/services/3.jpg"),

      products_close_icon: require("./assets/img/close-icon.svg"),
      products_1: require("./assets/img/products/1.jpg"),
      products_2: require("./assets/img/products/2.jpg"),
      products_3: require("./assets/img/products/3.jpg"),

      about_1: require("./assets/img/about/1.jpg"),
      about_2: require("./assets/img/about/2.jpg"),
    };
  },
  methods: {
    getCurrentYear() {
      const current = new Date();
      // const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
      return current.getFullYear();
    },
    setContactSubject() {
      document.getElementById("contactSubjectHidden").value =
        "Tekinventive: " + document.getElementById("contactName").value;
    },
  },
};
</script>


<style lang="scss">
@import "../scss/styles.scss";
</style>
