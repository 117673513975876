<template>
<IndexVue/>
<!-- <AnimationStars/> -->
</template>

<script>
import IndexVue from './components/Index.vue'
// import AnimationStars from './components/AnimationStars.vue'
// import AnimationStars from './components/AnimationMain.vue'

export default {
  name: 'App',
  components: {
    IndexVue,
    // AnimationStars
  },
  mounted() {
    // this.vantaEffect = window.VANTA.NET({
    //   el: "#mastheadBackground",
    //   mouseControls: true,
    //   touchControls: true,
    //   gyroControls: false,
    //   minHeight: 200.00,
    //   minWidth: 200.00,
    //   scale: 1.00,
    //   scaleMobile: 1.00,
    //   showDots: true,
    //   color: 0x41bdff,
    //   backgroundColor: 0x212529,  // gray-900, see https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss
    //   backgroundAlpha: 0.0,
    //   points: 10.00, // 10.00
    //   maxDistance: 20.00, // 20.00
    //   spacing: 16.00 // 16.00
    // })

    // this.vantaEffect = window.VANTA.HALO({
    //   el: "#mastheadBackground",
    //   mouseControls: true,
    //   touchControls: true,
    //   gyroControls: false,
    //   backgroundColor: 0x212529,
    //   backgroundAlpha: 0.0,
    //   minHeight: 200.00,
    //   minWidth: 200.00
    // })

    // this.vantaEffect = window.VANTA.DOTS({
    //   el: "#mastheadBackground",
    //   mouseControls: true,
    //   touchControls: true,
    //   gyroControls: false,
    //   color: 0x41bdff,
    //   backgroundColor: 0x212529,
    //   backgroundAlpha: 0.0,
    //   minHeight: 200.00,
    //   minWidth: 200.00,
    //   scale: 1.00,
    //   scaleMobile: 1.00,
    //   showLines: false
    // })

    // this.vantaEffect = window.VANTA.CELLS({
    //   el: "#mastheadBackground",
    //   mouseControls: true,
    //   touchControls: true,
    //   gyroControls: false,
    //   minHeight: 200.00,
    //   minWidth: 200.00,
    //   scale: 1.00,
    //   scaleMobile: 1.00,
    //   color1: 0x212529,  // gray-900, see https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss
    //   color2: 0x41bdff
    // })

    //   this.vantaEffect = window.VANTA.WAVES({
    //   el: "#mastheadBackground",
    //   mouseControls: true,
    //   touchControls: true,
    //   gyroControls: false,
    //   minHeight: 200.00,
    //   minWidth: 200.00,
    //   scale: 1.00,
    //   scaleMobile: 1.00,
    //   color: 0x212529,
    // })

    // ------ Google Analytics cookie ------
    // Define LaunchVueGtag function
    const LaunchVueGtag = function () {
      this.$gtag.config({ id: "G-7P6FP4568G" });
      this.$gtag.event("page_view");
      // console.log("Cookie launched.");
    }.bind(this);

    const cookiePolicy = document.querySelector("#cookie-policy");

    if (cookiePolicy) {
      const acceptButton = cookiePolicy.querySelector("#cookie-policy-accept");
      const declineButton = cookiePolicy.querySelector("#cookie-policy-decline");

      // Check if cookie policy was already accepted before
      const cookiePolicyAccepted = document.cookie.includes("cookie_policy_accepted=true");
      if (cookiePolicyAccepted) {
        cookiePolicy.style.display = "none";
        // console.log("Cookie policy is already accepted.");
        LaunchVueGtag();
      }

      // Define Accept button event
      acceptButton.addEventListener("click", () => {
        cookiePolicy.style.display = "none";
        document.cookie = "cookie_policy_accepted=true; path=/";
        // console.log("Cookie policy accepted.");
        LaunchVueGtag();
      });

      // Define Cancel button event
      declineButton.addEventListener("click", () => {
        cookiePolicy.style.display = "none";
        this.$gtag = undefined;
        // console.log("Cookie policy declined.");
      });
    }
    // ---------------------------------------
  },
  beforeUnmount() {
    if (this.vantaEffect) {
      this.vantaEffect.destroy();
    }
  },
};
</script>

<style>
</style>


